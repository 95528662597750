.slider-images-salon {
  .carousel-container {
    position: relative;
    margin: 0 auto;
  }

  .carousel-container {
    width: 100%;
    /* Adjust the width as needed */
    margin: 0 0.4%;
    /* Center the carousel horizontally */
  }

  .slick-slide img {
    width: 97%;
    margin: auto;
    /* Make images fill the width of their container */
    height: 330px;
    /* Set a fixed height for all images */
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 600px) {
    .slick-slide img {
      width: 100%;
      /* Make images fill the width of their container */
    }
  }

}
.slick-slide img:focus,
.slick-slide img:active {
  outline: none;
  border: none;
}


.description-salon div.ql-container {
  height: unset !important;
}