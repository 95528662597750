@tailwind base;
@tailwind components;
@tailwind utilities;

@import "bootstrap-icons/font/bootstrap-icons.css";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@import "react-toastify/dist/ReactToastify.css";