.linkactive{
    .icon{
        color: white;
        background-color: #FF9F66;
    }
    .title{
        color: #FF9F66;
    }
}

.linkdisable{
    .icon{
        color: #000000be;
        background-color: white;
    }
    .title{
        color: #000000be;
        font-size: 0.95rem;
    }
}

.linkdisable:hover{
    .icon{
        color: white;
        background-color: #FF9F66;
    }
    .title{
        color: #FF9F66;
    }
}
