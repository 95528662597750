:where(.css-dev-only-do-not-override-qnu6hi).ant-picker-outlined.ant-picker-disabled, :where(.css-dev-only-do-not-override-qnu6hi).ant-picker-outlined[disabled]{
    background-color: white !important;
    opacity: 0.7 !important;
    color: black !important;
    cursor:text !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-picker .ant-picker-input >input[disabled] {
    color: black !important;
    cursor:text !important;
}