.fc-event,
.fc-event-title {
  cursor: pointer;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .fc-header-toolbar {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .fc-header-toolbar {
    font-size: 12px;
  }
}

@media (max-width: 640px) {
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 40px;
  }
  .fc-toolbar-title {
    position: absolute;
    top: 47px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
  .fc-header-toolbar {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .fc-button-group .fc-button.fc-dayGridMonth-button {
    display: none;
  }
}

/* .fc-event{
  background-color: yellow;
  width: 100%;
  display: flex;
  justify-content: space-between;
} */

/* .fc-daygrid-event-harness{
  width: 100% !important;
  display: flex;
  justify-content: space-between;
} */

.fc-col-header-cell {
  font-size: 14px;
}

.justify-between {
  width: 100%;
}

.fc-list-event-graphic{
  display: none;
}

/* .fc .fc-button-primary{
    background-color: red;
}

.fc-button-active{
    background-color: yellow;
} */

.react-datepicker {
  font-size: 0.8rem;
  z-index: 10;
}

/* .fc-datePicker-button {
  position: relative;
} */

.fc .fc-button {
  /* background-color: rgba(253, 141, 72, 0.753) !important;
  border-color: rgba(253, 141, 72, 0.753) !important; */
  /* background-color: rgb(121, 121, 121) !important;
  border-color: rgb(121, 121, 121) !important; */
  color: white !important;
  font-weight: 500 !important;
}

.fc .fc-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* .fc .fc-button:hover { */
/* background-color: rgba(253, 141, 72, 0.925)  !important;
  border-color: rgba(253, 141, 72, 0.925) !important; */
/* background-color: rgb(80, 80, 80)  !important;
  border-color: rgb(80, 80, 80) !important; */
/* } */

.fc .fc-toolbar .fc-button.fc-button-active {
  /* background-color: rgb(121, 121, 121) !important;
  border-color: rgb(121, 121, 121) !important; */
  background-color: rgba(253, 141, 72, 0.753) !important;
  border-color: rgba(253, 141, 72, 0.753) !important;
  color: white !important;
}

.fc .fc-today-button:disabled {
  background-color: rgb(83, 83, 83) !important;
  border-color: rgb(83, 83, 83) !important;
}

.fc-daygrid-event {
  background-color: rgb(233, 233, 233) !important;
  margin: 2px 0px !important;
}

.fc-daygrid-event:hover {
  background-color: rgb(201, 231, 252) !important;
}

/* .fc-day-today{
    background-color: rgb(255, 208, 178) !important;
} */

.fc-scrollgrid-sync-inner {
  padding: 5px 0px !important;
}
/* .fc .fc-button-primary {
  background-color: #ffc107;
  border-color: #ffc107;
} */

/* 
.fc .fc-button:active {
  background-color: red;
  border-color: #004085;
}

.fc .fc-prev-button,
.fc .fc-next-button {
  background-color: #28a745;
  border-color: #28a745;
}

.fc .fc-today-button {
  background-color: #ffc107;
  border-color: #ffc107;
}

.fc .fc-datePicker-button {
  background-color: #17a2b8;
  border-color: #17a2b8;
}


.fc .fc-toolbar .fc-button.fc-button-active {
    background-color: red !important;
    border-color: red !important;
    color: white !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
} */
